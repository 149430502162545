import pic1 from '../images/Couple3.png'
import pic2 from '../images/GoldTrain.png'
import pic3 from '../images/redwall.JPG'
import pic4 from '../images/Stacy.png'
import pic5 from '../images/Peacock.JPG'
import pic6 from '../images/SilverRose.png'
import pic7 from '../images/Silver2.JPG'
import pic8 from '../images/SilverRose3.JPG'
import pic9 from '../images/roseoutside.JPG'
import pic10 from '../images/Silver-Prom.png'
import pic11 from '../images/blackspider.jpg'
import pic12 from '../images/Santa.JPG'
import pic13 from '../images/WhiteDress.png'
import pic14 from '../images/SilverRose5.JPG'
import pic15 from '../images/SilverProm2.png'
import pic16 from '../images/silverchairs.JPG'
import pic17 from '../images/IMG_9712.png'
import pic18 from '../images/IMG_6969.png'
import pic19 from '../images/GreenClose.jpg'
import pic20 from '../images/WhiteGoldWorn.jpg'
import pic21 from '../images/bluebently4.png'
import pic22 from '../images/bluebently2.jpg'



const picDB = [
    pic22,
    pic21,
    pic19,
    pic20,
    pic1,
    pic2,
    pic3,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
    pic10,
    pic11,
    pic12,
    pic13,
    pic14,
    pic15,
    pic16,
    pic17,
    pic18
];

export default picDB